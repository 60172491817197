// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-straw-contact-js": () => import("./../../../src/pages/straw/contact.js" /* webpackChunkName: "component---src-pages-straw-contact-js" */),
  "component---src-pages-straw-delivery-js": () => import("./../../../src/pages/straw/delivery.js" /* webpackChunkName: "component---src-pages-straw-delivery-js" */),
  "component---src-pages-straw-for-sale-js": () => import("./../../../src/pages/straw/for-sale.js" /* webpackChunkName: "component---src-pages-straw-for-sale-js" */),
  "component---src-pages-straw-index-js": () => import("./../../../src/pages/straw/index.js" /* webpackChunkName: "component---src-pages-straw-index-js" */),
  "component---src-pages-straw-success-js": () => import("./../../../src/pages/straw/success.js" /* webpackChunkName: "component---src-pages-straw-success-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-wood-contact-js": () => import("./../../../src/pages/wood/contact.js" /* webpackChunkName: "component---src-pages-wood-contact-js" */),
  "component---src-pages-wood-delivery-js": () => import("./../../../src/pages/wood/delivery.js" /* webpackChunkName: "component---src-pages-wood-delivery-js" */),
  "component---src-pages-wood-for-sale-js": () => import("./../../../src/pages/wood/for-sale.js" /* webpackChunkName: "component---src-pages-wood-for-sale-js" */),
  "component---src-pages-wood-index-js": () => import("./../../../src/pages/wood/index.js" /* webpackChunkName: "component---src-pages-wood-index-js" */)
}

